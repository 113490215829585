/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 1, 2018 */



@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk-bold-webfont.woff2') format('woff2'),
         url('hkgrotesk-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk-light-webfont.woff2') format('woff2'),
         url('hkgrotesk-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'HK Grotesk';
    src: url('hkgrotesk-regular-webfont.woff2') format('woff2'),
         url('hkgrotesk-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}