/* You can add global styles to this file, and also import other style files */

// Import toastr
@import 'node_modules/ngx-toastr/toastr';

// Import ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";

// Import angular material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Override bootstrap values
@import 'scss/theme2/style.blue';

@import '~quill/dist/quill.snow.css';

:root {
  --km-dark-blue: #072643FF;
  --km-blue: #0054C5FF;
  --km-blue-hover: #0047A4FF;
  --km-blue-active: #003880FF;
  --km-light-blue-bg: #F0F6FF;
  --km-light-green: #80FFCAFF;
  --km-light-red: #FEF0F2;
  --km-red: #D1111C;
  --km-light-gray: #F3F4F6FF;
}

@font-face {
  font-family: 'Work Sans';
  src: url('assets/fonts/worksans/WorkSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Work Sans';
  src: url('assets/fonts/worksans/WorkSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

$kiwa-red: #D1111C;
$kiwa-blue: #005DA1;
$kiwa-aqua: #00AAC5;
$kiwa-gray: #6F6F6F;

.toast-top-center {
  margin-top: 10px;
}

quill-editor {
  width: 100%;
  box-sizing: border-box;
  height: 300px;
  border-bottom: 1px solid #ccc;
  border-radius: 5px;

  .ql-container {
    border-radius: 0 0 5px 5px;
    overflow: hidden;
  }

  .ql-editor {
    background-color: var(--km-light-gray);
    border-radius: 0 0 5px 5px;
  }

  .ql-toolbar {
    border-radius: 5px 5px 0 0;
    border: 1px solid #ccc;
    background-color: white;
  }
}

.pointer {
  cursor: pointer;
}

.break-text {
  word-wrap: break-word;
}

.text-keep-enter {
  white-space: pre-wrap;
}

// cut text on product images
.product h3.h6 {
  overflow: hidden;
}

/* Dragula css */
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.fa-spin {
  letter-spacing: initial; // fix spinning
}
html, body { height: 100%; }
body { margin: 0; font-family: 'Work Sans'; }


/* kiwa color buttons */
.btn-kiwa-red {
  background-color: $kiwa-red;
  color: white;
}

.btn-kiwa-aqua {
  background-color: $kiwa-aqua;
  color: white;
}

.btn-kiwa-blue {
  background-color: $kiwa-blue;
  color: white;
}

.btn-kiwa-gray {
  background-color: $kiwa-gray;
  color: white;
}

.btn-outline-kiwa-aqua {
  border-color: $kiwa-aqua;
  color: $kiwa-aqua;
  background-color: white;

  &:hover {
    background-color: $kiwa-aqua;
    color: white;
  }
}

.btn-outline-kiwa-blue {
  border-color: $kiwa-blue;
  color: $kiwa-blue;

  &:hover {
    background-color: $kiwa-blue;
    color: white;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.htmlcontent {

  margin-bottom: 4em;
  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 22px;
    margin-top: 1.5em;
  }

  h4 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

}

.lower-z-index-backdrop {
  z-index: 1050 !important;
}

.modal {
  z-index: 2000 !important;
}

@media (max-width: 768px) {
  .htmlcontent {
    img {
        width: 100% !important;
        height: auto !important;
    }
  }
}

.sidebar-dialog {
  .col {
    padding: 8px;
  }
  label {
    color: white;
    font-weight: bold;
  }
  .body {
    input, textarea {
      color: var(--km-dark-blue);
    }
  }
}

/*ng-select*/
.ng-select-container {
  height: $input-height !important;
  
  // .ng-input {
  //   padding: 0px !important;
  // }
  .ng-value-container {
    padding: $input-padding-y $input-padding-x !important;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 10px;
}

.ng-select.gray .ng-select-container {
  background-color: #F3F4F6FF;
  border: 1px solid transparent;

  &:hover, &:focus-within {
    border: 1px solid #BDC1CAFF;
  }
}

button .spinner {
  height: 1rem;
  width: 1rem;
}
.km-btn {
  border: 1px solid transparent;
  font-family: 'Work Sans';
}
.km-btn:hover {
  border: 1px solid transparent;
}
.km-light-badge {
  font-size: 0.8rem;
}
.badge-success.km-light-badge {
  background-color: #EEFDF3;
  color: #6AAF81;
}
.badge-primary.km-light-badge {
  background-color: var(--km-light-blue-bg);
  color: #4E89D8;
}
.badge-danger.km-light-badge {
  background-color: var(--km-light-red);
  color: var(--km-red);
}
.km-btn-light-green {
  background-color: var(--km-light-green);
  color: var(--km-dark-blue);
}
.km-btn-light-green:hover {
  background-color: #6dd6aa;
}
.km-btn-white {
  background-color: white;
  color: var(--km-dark-blue);
}
.km-btn-white:hover {
  background-color: #81FFCA;
}
.km-btn-gray {
  background-color: #AEB0B3;
  color: white;
}
.km-btn-gray:hover {
  background-color: #939598;
}

.w-90 {
  width: 90%;
}

.is-invalid {
  input {
    @extend .is-invalid;
  }
}

.form-control.gray {
  background-color: var(--km-light-gray);
  border: 1px solid #F3F4F6FF;

  &:hover, &:focus-within {
    background-color: var(--km-light-gray);
    border: 1px solid #BDC1CAFF;
  }
}

.row {
  margin: 0px;
  [class^="col"]:first-child {
    padding-left: 0px;
  }
  [class^="col"]:last-child {
    padding-right: 0px;
  }
}

.content-container {
  width: 1500px;
  margin: 0 auto;
  @media screen and (max-width: 1500px) {
    width: 100%;
  }
}

.mat-calendar {
  border-radius: 8px;
  background-color: white;
}

.mat-calendar-header {
  padding-top: 0;
}

.mat-calendar-controls {
  margin-top: 0;
}

.mat-calendar-controls button {
  font-size: 24px;
}

.mdc-button__label {
  font-family: 'Work Sans';
  font-size: 20px; 
  line-height: 36px; 
  font-weight: 700; 
  color: #171A1FFF;
}

.mat-calendar-body-label {
  opacity: 0;
}

.mat-calendar .mat-calendar-body-label[colspan="7"] {
  display: none;
}

.mat-calendar .mat-calendar-table-header th {
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: var(--km-blue);
}

.mat-calendar .mat-calendar-body-selected {
  background-color: #0BA80069;
}

.mat-calendar button .mat-calendar-body-cell .mat-calendar-body-in-range {
  background-color: #0BA80069;
  color: var(--km-blue);
}

.mat-calendar .mat-calendar-body-in-range::before {
  color: white;
  background: #0BA80069;
}

.mat-calendar .mat-calendar-body-cell-content {
  font-family: 'Work Sans';
  font-size: 14px;
}

.mat-calendar .mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
  background-color: #0BA80069 !important;
}