/*
* ===================================================
*     Ekko lightbox additional theming
* ===================================================
*/

.ekko-lightbox {
    .modal-header {
        padding: 0;
    }
    .modal-title {
        display: none;
    }
    .ekko-lightbox-nav-overlay span {
        padding: 0 .5rem;
    }
    .nav-arrow {
        flex-grow: 0;
    }
    .nav-arrow-right {
        justify-self: right;
    }
    .close {
        position: absolute;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        vertical-align: top;
        background: theme-color('dark');
        color: $white;
        top: -5px;
        right: -5px;
        padding: 0;
        margin: 0;
        opacity: 1;
        font-size: 2rem;
        z-index: 999;
        cursor: pointer;
        outline: none;
        span {
            display: inline-block;
            position: relative;
            top: -2px;
            text-shadow: none;
            font-weight: normal;
        }
    }
    .modal-footer {
        justify-content: center;
        padding-top: 0;
    }
}