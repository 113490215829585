/*
* ===================================================
*     Top bar
* ===================================================
*/

.top-bar {
    @include media-breakpoint-down(sm) {
        //display: none;
    }
    color: $gray-700;
    background: white;
    // padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 300; 
    .dropdown-item {
        font-weight: 300;
    }
    .topbar-link {
        color: $gray-700;
        text-decoration: none;
    }
    .topbar-flag {
        margin-right: 10px;
        width: 20px;
    }

    .dropdown-menu {
        margin-top: 1.3rem;
    }
    .dropdown-toggle {
        color: inherit;
        text-decoration: none;
        background-color: white;
        border-color: white;
        &::after {
            margin-left: 5px;
        }
    }
    .dropdown-item {
        color: $gray-700;
        font-weight: 300;
    }
}

