.table {
    thead th,
    thead td {
      font-family: 'Work Sans';
      background-color: $table-head-bg;
      color: $table-head-color;
    }

    thead th,
    td {
      border: none;
      vertical-align: middle;
      height: 72px;
      font-size: 14px;
      font-weight: 500;
    }

}

.table-even-striped {
    tbody tr:nth-child(even) td {
      background-color: $table-head-bg;
    } 
}